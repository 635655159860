@tailwind base;
@tailwind components;
@tailwind utilities;

/* markdown */
@layer components {
  .markdown {
    @apply flex flex-col text-base sm:text-lg space-y-4 text-stone-700;
  }
  .markdown code {
    @apply text-xs sm:text-sm bg-stone-100 mx-1 py-1 px-2 rounded text-black;
  }
  .markdown pre code {
    @apply text-base p-0 m-0;
  }
  .markdown h2 {
    @apply font-bold text-xl sm:text-2xl pt-4 sm:pt-8 pb-2 first:pt-0 last:pb-0 text-stone-900;
  }
  .markdown h3 {
    @apply font-bold text-xl sm:text-2xl pt-2 sm:pt-4 pb-2 first:pt-0 last:pb-0 text-stone-900;
  }
  .markdown h4 {
    @apply font-bold text-lg sm:text-xl text-stone-900;
  }
  .markdown h5 {
    @apply font-bold text-lg text-stone-900;
  }
  .markdown h6 {
    @apply font-bold text-base text-stone-900;
  }
  .markdown ul {
    @apply pl-8 text-stone-900;
  }
  .markdown ul > li {
    @apply pl-2 list-disc;
  }
  .markdown ol {
    @apply pl-8 text-stone-900;
  }
  .markdown ol > li {
    @apply pl-2 list-decimal;
  }
  .markdown a {
    @apply text-red-400 hover:underline hover:text-red-500;
  }
  .markdown blockquote {
    @apply px-5 py-4 bg-stone-100 rounded-lg text-base flex flex-col space-y-1 border border-stone-200;
  }
  .markdown blockquote ul {
    @apply pl-5;
  }
  .markdown blockquote h3 {
    @apply text-base;
  }
}

/* content */
@layer components {
  .content {
    @apply flex flex-col space-y-4 lg:space-y-8;
  }
  .content h2 {
    @apply font-bold text-2xl;
  }
}

/* position */
@layer components {
  .flex-center {
    @apply flex justify-center items-center;
  }
  .flex-col-center {
    @apply flex flex-col justify-center items-center;
  }
  .flex-left {
    @apply flex justify-start items-center;
  }
  .flex-right {
    @apply flex justify-end items-center;
  }
  .flex-between {
    @apply flex justify-between items-center;
  }
}
